import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Framework from '../components/framework';
import Seo from '../components/framework/seo';

const IndexPage = ({ data, location }) => {
	const location_items = data.allLocation.edges
		.filter((value) => {
			if (value.node.partner.id !== 'testmottagningen') {
				return false;
			}
			return true;
		})
		.map((value) => {
			return value.node;
		});

	return (
		<Framework location={location}>
			<Seo title="TV" />
			<div className="container overflow-hidden p-4">
				{location_items.map((item, index) => {
					return (
						<p key={index}>
							<a className="btn btn-lg btn-light" href={`/${item.slug}`}>
								{item.name}
							</a>
						</p>
					);
				})}
			</div>
		</Framework>
	);
};

IndexPage.propTypes = {
	location: PropTypes.object.isRequired,
};

export default IndexPage;

export const query = graphql`
	query {
		allLocation(filter: { active: { eq: true } }) {
			edges {
				node {
					id
					name
					partner {
						id
					}
					slug
				}
			}
		}
	}
`;
